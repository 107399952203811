// NubeTech Util v1.0 (21032201)
import _ from 'lodash'
import moment from 'moment'

export function renderTimeOptions(date, shop) {
	// 確認是否為今日 || 無設定時間
	let isToday = date === moment().format('MM/DD') || date === null
	// 今日：由現在時間計算時間；非今日：由當日0:00開始計算
	let now = isToday ? moment().format('HH:mm'): moment(date).startOf('d').format('HH:mm')
	// 取得星期數
	let todayWeekDay = isToday ? moment().isoWeekday() - 1 : moment(date, 'MM/DD').isoWeekday() - 1
	// 商店的營業時間設定
	let time = shop.openingHours[todayWeekDay]
	// 商店的最短取餐時間設定
	let minWaitTime = shop.isBusy ? shop.busyTime : shop.readyTime
	let options = []
	// 透過商店的每個營業區間，產生可取餐的時間選項
	if (!time) {
		console.log('time')
		return []
	} else if (time.length === 0) {
		console.log('timeLength')
		return []
	} else if (date && moment(date, 'MM/DD').isBefore(moment().startOf('d'))) {
		console.log('date')
		return []
	}
	_.forEach(time, t => {
		let nowHour = Number(now.split(':')[0])
		let nowMinutes = Number(now.split(':')[1]) + minWaitTime
		let minutesArr = []
		// 計算minutesArr
		let computedMinutesArr =  Math.ceil(60/ shop.timeInterval)
		for (let i = 0; i < computedMinutesArr; i++) {
			minutesArr.push(i*shop.timeInterval)
		}
		// 透過現在時間，取得下一個最接近的取餐分鐘數
		if (nowMinutes >= minutesArr[minutesArr.length -1]) {
			let addHour = Math.floor(nowMinutes / 60)
			let addMin = Math.ceil((nowMinutes - addHour * 60) / shop.timeInterval) % computedMinutesArr
			nowHour += addHour
			if (addMin === 0) {
				nowHour += 1
				nowMinutes = 0
			} else {
				nowMinutes = minutesArr[addMin]
			}
		} else {
			nowMinutes = minutesArr[(Math.floor(nowMinutes /  shop.timeInterval) + 1) % computedMinutesArr]
		}
		let startHour = Number(t.start.split(':')[0])
		let startMinutes = Number(t.start.split(':')[1])
		let endTime = moment({
			h: t.end.split(':')[0],
			m: t.end.split(':')[1],
		}).format('HH:mm')
		let endHour = Number(endTime.split(':')[0])
		let endMinutes = Number(endTime.split(':')[1])
		// 取得計算選項的開始時間，如果現在時間 > 開店時間，則取用現在時間
		let startTime =
			nowHour > startHour || (nowHour === startHour && nowMinutes > startMinutes)
				? {
						h: nowHour,
						m: nowMinutes,
				  }
				: {
						h: startHour,
						m: startMinutes,
				  }
		// 取得產生選項的次數（15 min/次）
		let timeGap = (endHour - startTime.h) * minutesArr.length + (minutesArr.indexOf(endMinutes) - minutesArr.indexOf(startTime.m)) + 1
		for (let i = 0; i < timeGap; i++) {
			let obj = {
				label: moment(startTime)
					.add(shop.timeInterval * i, 'm')
					.format('HH:mm'),
				value: moment(startTime)
					.add(shop.timeInterval * i, 'm')
					.format('HH:mm'),
			}
			options.push(obj)
		}
	})
	return options
}

export function sha256(ascii) {
	function rightRotate(value, amount) {
		return (value >>> amount) | (value << (32 - amount))
	}

	var mathPow = Math.pow
	var maxWord = mathPow(2, 32)
	var lengthProperty = 'length'
	var i, j // Used as a counter across the whole file
	var result = ''

	var words = []
	var asciiBitLength = ascii[lengthProperty] * 8

	//* caching results is optional - remove/add slash from front of this line to toggle
	// Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
	// (we actually calculate the first 64, but extra values are just ignored)
	var hash = (sha256.h = sha256.h || [])
	// Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
	var k = (sha256.k = sha256.k || [])
	var primeCounter = k[lengthProperty]
	/*/
    var hash = [], k = [];
    var primeCounter = 0;
    //*/

	var isComposite = {}
	for (var candidate = 2; primeCounter < 64; candidate++) {
		if (!isComposite[candidate]) {
			for (i = 0; i < 313; i += candidate) {
				isComposite[i] = candidate
			}
			hash[primeCounter] = (mathPow(candidate, 0.5) * maxWord) | 0
			k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0
		}
	}

	ascii += '\x80' // Append Ƈ' bit (plus zero padding)
	while ((ascii[lengthProperty] % 64) - 56) ascii += '\x00' // More zero padding
	for (i = 0; i < ascii[lengthProperty]; i++) {
		j = ascii.charCodeAt(i)
		if (j >> 8) return // ASCII check: only accept characters in range 0-255
		words[i >> 2] |= j << (((3 - i) % 4) * 8)
	}
	words[words[lengthProperty]] = (asciiBitLength / maxWord) | 0
	words[words[lengthProperty]] = asciiBitLength

	// process each chunk
	for (j = 0; j < words[lengthProperty]; ) {
		var w = words.slice(j, (j += 16)) // The message is expanded into 64 words as part of the iteration
		var oldHash = hash
		// This is now the undefinedworking hash", often labelled as variables a...g
		// (we have to truncate as well, otherwise extra entries at the end accumulate
		hash = hash.slice(0, 8)

		for (i = 0; i < 64; i++) {
			// Expand the message into 64 words
			// Used below if
			var w15 = w[i - 15],
				w2 = w[i - 2]

			// Iterate
			var a = hash[0],
				e = hash[4]
			var temp1 =
				hash[7] +
				(rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) + // S1
				((e & hash[5]) ^ (~e & hash[6])) + // ch
				k[i] +
				// Expand the message schedule if needed
				(w[i] =
					i < 16
						? w[i]
						: (w[i - 16] +
						  (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) + // s0
								w[i - 7] +
								(rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10))) | // s1
						  0)
			// This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
			var temp2 =
				(rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) + // S0
				((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])) // maj

			hash = [(temp1 + temp2) | 0].concat(hash) // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
			hash[4] = (hash[4] + temp1) | 0
		}

		for (i = 0; i < 8; i++) {
			hash[i] = (hash[i] + oldHash[i]) | 0
		}
	}

	for (i = 0; i < 8; i++) {
		for (j = 3; j + 1; j--) {
			var b = (hash[i] >> (j * 8)) & 255
			result += (b < 16 ? 0 : '') + b.toString(16)
		}
	}
	return result
}
